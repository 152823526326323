.container {
  /* height: 100vh; */
  /* height: 80vh; */
  /* height: calc(100vh-100px); */
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  background: rgb(63, 73, 204);
}

.flex-fill {
  flex:1;
}

.chatContainer {
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 20px;
}

/* Room and users component */
.roomAndUsersColumn {
  border-right: 1px solid #dfdfdf;
}
.roomTitle {
  margin-bottom: 60px;
  text-transform: uppercase;
  font-size: 2rem;
  color: #fff;
}
.usersTitle {
  font-size: 1.2rem;
  color: #fff;
}
.usersList {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 60px;
  color: rgb(153, 217, 234);
}
.usersList li {
  margin-bottom: 12px;
}

/* Messages */
.messagesColumn {
  background-color: darkblue;
  /* height: 65vh; */
  /* height:100%; */
  height: calc(100vh - 340px);
  overflow: auto;
  padding: 10px 10px 10px 10px;
}
.message {
  background: rgb(83, 103, 172);
  border-radius: 4px;
  margin-bottom: 12px;
  /* max-width: 600px; */
  padding-left: 12px;
  padding-top: 12px;
  padding-right: 12px;
  padding-bottom: 1px;
}
.msgMeta {
  color: rgb(153, 217, 234);
  font-size: 0.75rem;
}
.msgText {
  color: #fff;
}

/* Message input and button */
.sendMessageContainer {
  padding: 16px 20px 20px 16px;
}
.messageInput {
  padding: 14px;
  margin-right: 16px;
  width: 60%;
  border-radius: 6px;
  border: 1px solid rgb(153, 217, 234);
  font-size: 0.9rem;
}

.linkButton {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  /* color: #069; */
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.btncircle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.BoxWithScrollbars {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 300px;
  width: 80%;
  overflow-y: scroll;
  overflow-x: scroll;
  border: #ddd solid 1px;
  padding: 5px;
}

.CloseButton {
  position: relative;
  /* width: 100%; */
   /* height: 100%;   100% means that both lines will take the entire space */
  height: 24px;  /* 100% means that both lines will take the entire space */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  color: white;
  background-color: red;
}